import React, { useState } from "react";
import { API } from "aws-amplify";

import {
    Heading,
    Text,
    View,
    Collection,
    Flex,
    Button,
    TextField,
    SelectField,
    Link,
} from "@aws-amplify/ui-react";

function Modal({ isOpen, onClose, children }) {
    if (!isOpen) return null;

    return (
        <View
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            backgroundColor="rgba(0, 0, 0, 0.5)"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <View
                backgroundColor="white"
                padding="30px 30px 30px 30px"
                borderRadius={2}
                width={400}
            >
                {/* View to show close button in form of bix X in top right corner of the modal
            <View
                cursor="pointer"
                onClick={onClose}
            >
                <Text fontSize={24} fontWeight="bold">
                    ✕
                </Text>
            </View>
            */}
                {children}
                <br />
                <br />
                <View align="center">
                    <Button size="small" onClick={onClose}>
                        Discard Changes
                    </Button>
                </View>
            </View>
        </View>
    );
}

export function PerUserPayouts({ tasks, profiles, payouts, jwt, accessLevel }) {
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(navigator.userAgent);
    const [isMobile] = useState(isMobileDevice);

    /*
    console.log("tasks: ", tasks);
    console.log("profiles: ", profiles);
    console.log("payouts: ", payouts);
    console.log("jwt: ", jwt);
    console.log("accessLevel: ", accessLevel);
    */
    if (payouts?.length > 0) {
        payouts.sort((a, b) => {
            return b.lastUpdated - a.lastUpdated;
        });
    }

    if (tasks?.length > 0) {
        tasks.sort((a, b) => {
            //but "RequestTime": "2023-08-14T21:05:34.428Z", so make it as Date object to compare
            return new Date(b.RequestTime) - new Date(a.RequestTime);
        });
    }

    /*
    console.log("tasks:", tasks);
    console.log("profiles:", profiles);
    console.log("payouts:", payouts);
     */
    /* tasks example
    
    [
    {
        "quantity": 5,
        "postUrl": "https://www.linkedin.com/feed/update/urn:li:activity:7087132058698805248",
        "stage": "completed",
        "msg": "comment not validated;", 
        "requestIPCountry": "none",
        "followers": 6787,
        "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
        "avatarUrl": "1689706264484-avatar.png",
        "attempted": true,
        "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
        "validated": true,
        "needsComment": true,
        "balance": 0,
        "RequestTime": "2023-08-14T21:05:34.428Z",
        "payout": "USER PAYOUT 1726858747",
        "requestType": "PAID",
        "like": "Like",
        "comment": "\"In a nutshell, a hardworking man sadly passed away from heat exhaustion at a construction site. His brave daughter is now fighting for justice and changes to make sure something like this doesn't happen again. Stay safe in high temperatures, and remember to hydrate!\"\n\nAlso, here's a friendly reminder that even adults need to take breaks and drink plenty of wa,ter when it's hot outside. Let's all look out for our colleagues, especially in these sweltering summer months.",
        "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
        "SK": "TASK CAMPAIGN https://www.linkedin.com/feed/update/urn:li:activity:7087132058698805248 2023-07-18T18:47:30.045Z",
        "intro": "",
        "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
        "needsIntro": false,
        "authorUser": "4b640b8e-e7b9-4fd3-8748-84b93449b244"
    },
    {
        "quantity": 5,
        "postUrl": "https://www.linkedin.com/feed/update/urn:li:activity:7092128112863268864/",
        "stage": "completed",
        "requestIPCountry": "none",
        "followers": 9218,
        "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
        "avatarUrl": "1690901211468-avatar.png",
        "attempted": true,
        "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
        "validated": true,
        "needsComment": true,
        "balance": 0,
        "RequestTime": "2023-08-14T13:01:11.934Z",
        "payout": "USER PAYOUT 1726858747",
        "requestType": "PAID",
        "like": "Support",
        "comment": "Big car companies are starting to sell new types of fuel for cars. They are also changing what they offer with their cars, like special charging bundles for electric cars. Here's how many new cars and special fuel cars they had for sale:\n\n- AutoNation had 41,623 cars, with 7,962 special fuel cars (19.1%)\n- Lithia & Driveway had 39,970 cars, with 3,320 special ,fuel cars (8.4%)\n- Hendrick Automotive Group had 18,182 cars, with 6,050 special fuel cars (33.2%)\n- Sonic Automotive had 11,749 cars, with 2,173 special fuel cars (18.5%)\n- Asbury Automotive Group had 10,487 cars, with 2,269 special fuel cars (21.6%)\n- And so on. \n\nAlso, more than half of customers are buying the special charging bundles for their cars, which is making the car companies extra money.",
        "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
        "SK": "TASK CAMPAIGN https://www.linkedin.com/feed/update/urn:li:activity:7092128112863268864/ 2023-08-01T14:46:33.837Z",
        "intro": "",
        "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
        "needsIntro": false,
        "authorUser": "37546459-6035-4bd1-a10e-22b8a8b421ae"
    }
    ]*/

    /* profiles example

    {
    "payments": "inactive",
    "date_boost_1": "2023-06-08",
    "date_boost_2": "2023-06-08",
    "fiatBalance": 0,
    "ip_boost_last": "79.143.42.82",
    "plan_upgrade_last_try": "pro",
    "date_upgraded_last": "2023-06-08",
    "plan_churn_1st": "debug",
    "plan_upgraded_last": "plus",
    "accessLevel": "admin",
    "date_upgrade_last_try": "2023-07-31T10:27:11.123Z",
    "email": "serhiy@kfginternational.com",
    "pods": "",
    "subscriptionPlan": "none",
    "profile_campaign_last": "https://www.linkedin.com/in/serhiy-khvashchuk",
    "date_campaign_last": "2024-03-19",
    "last_profile_url": "https://www.linkedin.com/in/marketing-advisor/",
    "RequestTime": "2024-03-19T20:30:42Z",
    "country_code_upgrade_last_try": "es",
    "plan_upgraded_1st": "plus",
    "SK": "USER",
    "ip_campaign_last": "171.33.234.174",
    "starsBalance": 100,
    "firstLogin": true,
    "APIKey": "TyMuT9LACUoq0UYAEgwDVhDr_vtHXtFO3OrM3IjsJDw",
    "ip_boost_2": "171.33.234.174",
    "ip_boost_1": "171.33.234.174",
    "date_boost_last": "2022-02-22",
    "intros": "",
    "date_upgraded_1st": "2023-06-08",
    "createdDate": "2020-06-20T16:46:51.772Z",
    "postBalance": 0,
    "date_churn_1st": "2023-06-07",
    "ip_upgrade_last_try": "171.33.234.174",
    "subscriptionId": "sub_1NGnOXHmnakvBD28OhQEcVcy",
    "comments": "",
    "profile_boost_last": "https://www.linkedin.com/in/serhiykhvashchuk",
    "last_profile_add_started": "2023-08-04T18:47:24.655Z",
    "date_churn_last": "2023-06-08",
    "sortKey": "user",
    "uuid": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
    "profile_boost_2": "https://www.linkedin.com/in/sviatoslav-pinchuk",
    "profile_boost_1": "https://www.linkedin.com/in/sviatoslav-pinchuk",
    "country_upgrade_last_try": "Spain",
    "customerId": "cus_O2tAOS1WoxdGx3"
    }
    */

    /* payouts example
[
    {
        "tasks": [
            {
                "msg": "comment not validated;",
                "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
                "postUrl": "https://www.linkedin.com/feed/update/urn:li:activity:7087132058698805248",
                "quantity": 5,
                "requestType": "PAID",
                "avatarUrl": "1689706264484-avatar.png",
                "like": "Like",
                "needsIntro": false,
                "needsComment": true,
                "RequestTime": "2023-08-14T21:05:34.428Z",
                "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
                "followers": 6787,
                "stage": "completed",
                "validated": true,
                "balance": 0,
                "authorUser": "4b640b8e-e7b9-4fd3-8748-84b93449b244",
                "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
                "intro": "",
                "SK": "TASK CAMPAIGN https://www.linkedin.com/feed/update/urn:li:activity:7087132058698805248 2023-07-18T18:47:30.045Z",
                "requestIPCountry": "none",
                "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
                "comment": "\"In a nutshell, a hardworking man sadly passed away from heat exhaustion at a construction site. His brave daughter is now fighting for justice and changes to make sure something like this doesn't happen again. Stay safe in high temperatures, and remember to hydrate!\"\n\nAlso, here's a friendly reminder that even adults need to take breaks and drink plenty of wa,ter when it's hot outside. Let's all look out for our colleagues, especially in these sweltering summer months.",
                "attempted": true
            },
            {
                "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
                "postUrl": "https://www.linkedin.com/feed/update/urn:li:activity:7092128112863268864/",
                "quantity": 5,
                "requestType": "PAID",
                "avatarUrl": "1690901211468-avatar.png",
                "like": "Support",
                "needsIntro": false,
                "needsComment": true,
                "RequestTime": "2023-08-14T13:01:11.934Z",
                "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
                "followers": 9218,
                "stage": "completed",
                "validated": true,
                "balance": 0,
                "authorUser": "37546459-6035-4bd1-a10e-22b8a8b421ae",
                "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
                "intro": "",
                "SK": "TASK CAMPAIGN https://www.linkedin.com/feed/update/urn:li:activity:7092128112863268864/ 2023-08-01T14:46:33.837Z",
                "requestIPCountry": "none",
                "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
                "comment": "Big car companies are starting to sell new types of fuel for cars. They are also changing what they offer with their cars, like special charging bundles for electric cars. Here's how many new cars and special fuel cars they had for sale:\n\n- AutoNation had 41,623 cars, with 7,962 special fuel cars (19.1%)\n- Lithia & Driveway had 39,970 cars, with 3,320 special ,fuel cars (8.4%)\n- Hendrick Automotive Group had 18,182 cars, with 6,050 special fuel cars (33.2%)\n- Sonic Automotive had 11,749 cars, with 2,173 special fuel cars (18.5%)\n- Asbury Automotive Group had 10,487 cars, with 2,269 special fuel cars (21.6%)\n- And so on. \n\nAlso, more than half of customers are buying the special charging bundles for their cars, which is making the car companies extra money.",
                "attempted": true
            }
                        ],
        "uuid": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
        "SK": "USER PAYOUT 1726858747",
        "stage": "requested",
        "payoutDestinationType": "PayPal",
        "payoutDestination": "support@kfginternational.com"
    }
    ]
    or []
    */

    const [modalShown, setShowModal] = useState(false);
    const [payoutDestinationType, setPayoutDestinationType] = useState(
        profiles?.payoutDestinationType || "PayPal"
    );
    const [payoutDestination, setPayoutDestination] = useState(
        profiles?.payoutDestination || ""
    );
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [payoutDestinationTypeError, setPayoutDestinationTypeError] =
        useState(false);
    const [payoutDestinationError, setPayoutDestinationError] = useState(false);

    const [saveLoading, setSaveLoading] = useState(false);

    const [payoutSumRequested, setPayoutSumRequested] = useState(0);
    const [payoutSumPaid, setPayoutSumPaid] = useState(0);
    const [payoutComment, setPayoutComment] = useState("");

    const [modalAdminShown, setAdminShowModal] = useState(false);

    const [payoutStage, setPayoutStage] = useState("requested");

    const [payoutSK, setPayoutSK] = useState("");

    const [payoutTasks, setPayoutTasks] = useState(
        tasks.filter(
            (task) =>
                //don't have payout key
                !task.payout &&
                //are over 15 days old
                new Date(task.RequestTime).getTime() <
                    Date.now() - 15 * 24 * 60 * 60 * 1000
        ) || []
    );

    async function handlePayoutUpdate() {
        const linuxTimestamp = Math.floor(Date.now() / 1000);
        //add payout = `USER PAYOUT ${linuxTimestamp}` to each task
        //it new payout requested
        if (payoutStage === "requested") {
            setPayoutSK(`USER PAYOUT ${linuxTimestamp}`);
            setPayoutTasks(
                payoutTasks.map((task) => {
                    return { ...task, payout: `USER PAYOUT ${linuxTimestamp}` };
                })
            );
        }

        const request = {
            headers: {
                Authorization: `${jwt}`,
            },
            body: {
                payoutDestinationType,
                payoutDestination,
                uuid: profiles.uuid,
                SK:
                    payoutSK.length > 0
                        ? payoutSK
                        : `USER PAYOUT ${linuxTimestamp}`,
                stage: payoutStage,
                //tasks which are without payouts key
                tasks: payoutTasks,
                lastUpdated: `${linuxTimestamp}`,
                sumRequested: payoutSumRequested,
                sumPaid: payoutSumPaid,
                comment: payoutComment,
            },
        };
        try {
            //const data =
            await API.post("gcAPI", `/update-payout`, request);
            //console.log("data:", data);
            setPayoutSK("");
            setShowModal(false);
            setModalButtonLoading(false);
            setSaveLoading(false);
            //trigger reload of the page
            window.location.reload();
        } catch (e) {
            console.log("problem calling api", e);
            alert("Problem calling API. Please try again after page refresh.");
            window.location.reload();
        }
    }

    let readyPayouts = 0;
    tasks.forEach((task) => {
        if (
            task.payout ||
            new Date(task.RequestTime).getTime() >
                Date.now() - 15 * 24 * 60 * 60 * 1000
        ) {
        } else {
            if (task.requestType === "PAID") readyPayouts += task.quantity;
            if (task.requestType === "PAID-REPOST")
                readyPayouts += task.quantity * 10;
        }
    });

    return (
        <>
            {accessLevel === "admin" ? (
                <>
                    Profile: {tasks?.[0]?.profileUrl}
                    <br />
                    UUID: {profiles.uuid}
                    <br />
                    <span
                        style={{
                            color:
                                readyPayouts !== profiles.fiatBalance
                                    ? "red"
                                    : "black",
                        }}
                    >
                        ${readyPayouts} Ready for Payout from $
                        {profiles.fiatBalance} Balance
                    </span>
                </>
            ) : (
                ""
            )}
            <Heading level={4} fontWeight={"bold"} textAlign="center">
                {profiles?.lifetimePayouts ? (
                    <>
                        Lifetime Payouts ${profiles.lifetimePayouts}
                        <br />
                    </>
                ) : (
                    ""
                )}
                <br />
                <Button
                    //if no tasks without payout key - show button in disabled state (add ? icon with the explanation)
                    disabled={
                        payoutTasks.length < 1 ||
                        readyPayouts < 1 ||
                        saveLoading
                    }
                    id={profiles?.uuid ? profiles.uuid : ""}
                    onClick={() => {
                        //if not payoutDestinationType or payoutDestination - show modal
                        //else - call handlePayoutUpdate directly
                        setPayoutStage("requested");
                        if (
                            !profiles.payoutDestinationType ||
                            !profiles.payoutDestination
                        ) {
                            setShowModal(true);
                        } else {
                            setSaveLoading(true);
                            handlePayoutUpdate();
                        }
                    }}
                    size="large"
                    variation="primary"
                    isLoading={saveLoading}
                >
                    REQUEST PAYOUT
                </Button>
                <br />
                {profiles?.payoutDestination && readyPayouts > 0 ? (
                    <>
                        Payout Destination: {profiles.payoutDestinationType}{" "}
                        {profiles.payoutDestination}
                        <br />
                        <Button
                            //if no tasks without payout key - show button in disabled state (add ? icon with the explanation)
                            disabled={payoutTasks.length < 1 || saveLoading}
                            id={profiles.uuid}
                            onClick={() => {
                                setShowModal(true);
                            }}
                            size="small"
                            //variation="primary"
                            //isLoading={saveLoading}
                        >
                            PAYOUT TO ANOTHER DESTINATION
                        </Button>
                    </>
                ) : (
                    ""
                )}
            </Heading>

            <br />
            {/* Unpaid tasks first */}
            <Collection
                type="grid"
                gap="20px"
                items={tasks.filter(
                    (task) =>
                        //don't have payout key
                        !task.payout
                )}
                searchNoResultsFound={
                    <Flex justifyContent="center">
                        <Text>No ready for payout tasks found.</Text>
                        <br />
                    </Flex>
                }
            >
                {(task, index) => (
                    <Flex
                        key={index}
                        direction={{ base: "column", large: "row" }}
                        style={{
                            width: "100%",
                            backgroundColor:
                                // if task.RequestTime less then 15 days from now - show grey,else white
                                new Date(task.RequestTime).getTime() >
                                Date.now() - 15 * 24 * 60 * 60 * 1000
                                    ? "#f0f0f0" //grey
                                    : "white",
                        }}
                    >
                        <View padding="medium" style={{ flexGrow: 1 }}>
                            {index < 1 || isMobile ? (
                                <>
                                    <u>#</u>
                                    <br />
                                    {index < 1 && !isMobile ? <br /> : ""}
                                </>
                            ) : (
                                ""
                            )}
                            <Text>{index + 1}</Text>
                        </View>
                        <View padding="medium" style={{ flexGrow: 1 }}>
                            {index < 1 || isMobile ? (
                                <>
                                    <u>Task Link</u>
                                    <br />
                                    {index < 1 && !isMobile ? <br /> : ""}
                                </>
                            ) : (
                                ""
                            )}
                            <Link href={task.postUrl} target="_blank">
                                {isMobile
                                    ? //show only 1st 50 characters of the postUrl
                                      task.postUrl.substring(0, 40) + "..."
                                    : task.postUrl.substring(0, 80) + "..."}
                            </Link>
                        </View>
                        <View padding="medium" style={{ flexGrow: 1 }}>
                            {index < 1 || isMobile ? (
                                <>
                                    <u>Sum</u>
                                    <br />
                                    {index < 1 && !isMobile ? <br /> : ""}
                                </>
                            ) : (
                                ""
                            )}
                            <Text>
                                $
                                {task.requestType.startsWith("PAID-")
                                    ? task.quantity * 10
                                    : task.quantity}
                            </Text>
                        </View>
                        <View padding="medium" style={{ flexGrow: 1 }}>
                            {index < 1 || isMobile ? (
                                <>
                                    <u>Status:</u>
                                    <br />
                                    {index < 1 && !isMobile ? <br /> : ""}
                                </>
                            ) : (
                                ""
                            )}
                            <Text>
                                {task.payout
                                    ? "Being paid"
                                    : new Date(task.RequestTime).getTime() >
                                      Date.now() - 15 * 24 * 60 * 60 * 1000
                                    ? `To be available at ${new Date(
                                          new Date(task.RequestTime).getTime() +
                                              15 * 24 * 60 * 60 * 1000
                                      )
                                          .toISOString()
                                          .split("T")
                                          .shift()}`
                                    : "Good for payout"}
                            </Text>
                        </View>
                        <View padding="medium" style={{ flexGrow: 1 }}>
                            {index < 1 || isMobile ? (
                                <>
                                    <u>Date:</u>
                                    <br />
                                    {index < 1 && !isMobile ? <br /> : ""}
                                </>
                            ) : (
                                ""
                            )}
                            <Text>{task.RequestTime.split("T").shift()}</Text>
                        </View>
                    </Flex>
                )}
            </Collection>

            {/* Payouts with tasks */}
            {payouts
                ? payouts.map((payout, i) =>
                      !("comment" in payout) ||
                      !payout.comment.includes("ARCHIVE") ? (
                          <React.Fragment key={`payout-${i}-${payout.SK}`}>
                              {/* Payout itself */}
                              <Flex
                                  key={i}
                                  direction={{ base: "column", large: "row" }}
                                  style={{
                                      backgroundColor:
                                          payout.stage === "requested"
                                              ? "#faf3e1" //orange
                                              : "#e1fae1", //"verylightgreen"
                                  }}
                              >
                                  {payout.tasks.length > 0 ? (
                                      <>
                                          <View
                                              padding="medium"
                                              style={{ flexGrow: 1 }}
                                          >
                                              Payout for {payout.tasks.length}{" "}
                                              tasks completed
                                              <br />
                                              For: ${payout.sumRequested}
                                              <br />
                                              Less 15% platform fee: $
                                              {(
                                                  payout.sumRequested -
                                                  payout.sumPaid
                                              ).toFixed(2)}
                                              <br />
                                              Final sum to be sent: $
                                              {payout.sumPaid}
                                              <br />
                                              To: {
                                                  payout.payoutDestinationType
                                              }{" "}
                                              {payout.payoutDestination}
                                              <br />
                                              Last updated:{" "}
                                              {new Date(
                                                  payout.lastUpdated * 1000
                                              )
                                                  .toISOString()
                                                  .split("T")
                                                  .shift()}
                                              <br />
                                              {
                                                  //if accessLevel is admin - show input to edit sumRequested, sumPaid, comment in one line text field
                                                  //and button to save changes
                                                  //otherwise - show nothing
                                                  accessLevel === "admin" &&
                                                  payout.stage !== "sent" ? (
                                                      <>
                                                          <br />
                                                          <Button
                                                              onClick={() => {
                                                                  //set payoutSumRequested, payoutSumPaid, payoutComment
                                                                  setPayoutSumRequested(
                                                                      payout.sumRequested
                                                                  );

                                                                  setPayoutSumPaid(
                                                                      payout.sumPaid
                                                                  );

                                                                  setPayoutComment(
                                                                      payout.comment ||
                                                                          ""
                                                                  );

                                                                  setPayoutSK(
                                                                      payout.SK
                                                                  );

                                                                  setPayoutTasks(
                                                                      payout.tasks
                                                                  );

                                                                  setPayoutStage(
                                                                      "sent"
                                                                  );

                                                                  setAdminShowModal(
                                                                      true
                                                                  );
                                                              }}
                                                              size="small"
                                                              variation="primary"
                                                          >
                                                              SEND PAYOUT
                                                          </Button>
                                                          <Modal
                                                              isOpen={
                                                                  modalAdminShown
                                                              }
                                                              onClose={() =>
                                                                  setAdminShowModal(
                                                                      false
                                                                  )
                                                              }
                                                          >
                                                              <Heading
                                                                  level={3}
                                                                  fontWeight={
                                                                      "bold"
                                                                  }
                                                                  textAlign="center"
                                                              >
                                                                  Edit Payout
                                                                  Details
                                                              </Heading>
                                                              <br />
                                                              <br />
                                                              <TextField
                                                                  label="Comment"
                                                                  value={
                                                                      payoutComment
                                                                  }
                                                                  onChange={(
                                                                      e
                                                                  ) =>
                                                                      setPayoutComment(
                                                                          e
                                                                              .target
                                                                              .value ||
                                                                              ""
                                                                      )
                                                                  }
                                                              />
                                                              <br />
                                                              <TextField
                                                                  label="Update Sum Requested"
                                                                  value={
                                                                      payoutSumRequested
                                                                  }
                                                                  onChange={(
                                                                      e
                                                                  ) =>
                                                                      setPayoutSumRequested(
                                                                          e
                                                                              .target
                                                                              .value
                                                                      )
                                                                  }
                                                              />
                                                              <br />
                                                              <TextField
                                                                  label="Update Sum Paid"
                                                                  value={
                                                                      payoutSumPaid
                                                                  }
                                                                  onChange={(
                                                                      e
                                                                  ) =>
                                                                      setPayoutSumPaid(
                                                                          e
                                                                              .target
                                                                              .value
                                                                      )
                                                                  }
                                                              />
                                                              <br />
                                                              <br />
                                                              <View align="center">
                                                                  <Button
                                                                      onClick={() => {
                                                                          setSaveLoading(
                                                                              true
                                                                          );
                                                                          //collect data from fields and call handlePayoutUpdate
                                                                          handlePayoutUpdate();
                                                                      }}
                                                                      variation="primary"
                                                                      size="large"
                                                                      isLoading={
                                                                          saveLoading
                                                                      }
                                                                      disabled={
                                                                          saveLoading
                                                                      }
                                                                  >
                                                                      SEND
                                                                      PAYOUT
                                                                  </Button>
                                                              </View>
                                                          </Modal>
                                                      </>
                                                  ) : accessLevel ===
                                                    "admin" ? (
                                                      <>
                                                          <br />
                                                          <Text>
                                                              ADMIN ONLY
                                                              COMMENT:{" "}
                                                              {payout.comment}
                                                          </Text>
                                                      </>
                                                  ) : (
                                                      ""
                                                  )
                                              }
                                          </View>
                                          <View
                                              padding="medium"
                                              style={{ flexGrow: 1 }}
                                          >
                                              ${payout.sumPaid}
                                          </View>
                                          <View
                                              padding="medium"
                                              style={{ flexGrow: 1 }}
                                          >
                                              {payout.stage}
                                          </View>
                                          <View
                                              padding="medium"
                                              style={{ flexGrow: 1 }}
                                          >
                                              {new Date(
                                                  payout.lastUpdated * 1000
                                              )
                                                  .toISOString()
                                                  .split("T")
                                                  .shift()}
                                          </View>
                                      </>
                                  ) : (
                                      ""
                                  )}
                              </Flex>

                              {/* Payout's tasks */}
                              <Collection
                                  type="grid"
                                  gap="20px"
                                  items={payout.tasks}
                                  searchNoResultsFound={
                                      <Flex justifyContent="center">
                                          <Text>
                                              No tasks for this payout found.
                                          </Text>
                                          <br />
                                      </Flex>
                                  }
                              >
                                  {(task, j) => (
                                      <Flex
                                          key={`payout-${j}-${payout.SK}-task-${task.SK}-profile-${profiles.uuid}`}
                                          direction={{
                                              base: "column",
                                              large: "row",
                                          }}
                                      >
                                          <View
                                              padding="medium"
                                              style={{ flexGrow: 1 }}
                                          >
                                              {j < 1 || isMobile ? (
                                                  <>
                                                      <u>#</u>
                                                      <br />
                                                      {j < 1 && !isMobile ? (
                                                          <br />
                                                      ) : (
                                                          ""
                                                      )}
                                                  </>
                                              ) : (
                                                  ""
                                              )}
                                              <Text>{j + 1}</Text>
                                          </View>
                                          <View
                                              padding="medium"
                                              style={{ flexGrow: 1 }}
                                          >
                                              {j < 1 || isMobile ? (
                                                  <>
                                                      <u>Task Link</u>
                                                      <br />
                                                      {j < 1 && !isMobile ? (
                                                          <br />
                                                      ) : (
                                                          ""
                                                      )}
                                                  </>
                                              ) : (
                                                  ""
                                              )}
                                              <Link
                                                  href={task.postUrl}
                                                  target="_blank"
                                              >
                                                  {isMobile
                                                      ? //show only 1st 50 characters of the postUrl
                                                        task.postUrl.substring(
                                                            0,
                                                            40
                                                        ) + "..."
                                                      : task.postUrl.substring(
                                                            0,
                                                            80
                                                        ) + "..."}
                                              </Link>
                                          </View>
                                          <View
                                              padding="medium"
                                              style={{ flexGrow: 1 }}
                                          >
                                              {j < 1 || isMobile ? (
                                                  <>
                                                      <u>Sum</u>
                                                      <br />
                                                      {j < 1 && !isMobile ? (
                                                          <br />
                                                      ) : (
                                                          ""
                                                      )}
                                                  </>
                                              ) : (
                                                  ""
                                              )}
                                              <Text>
                                                  $
                                                  {task.requestType.startsWith(
                                                      "PAID-"
                                                  )
                                                      ? task.quantity * 10
                                                      : task.quantity}
                                              </Text>
                                          </View>
                                          <View
                                              padding="medium"
                                              style={{ flexGrow: 1 }}
                                          >
                                              {j < 1 || isMobile ? (
                                                  <>
                                                      <u>Status:</u>
                                                      <br />
                                                      {j < 1 && !isMobile ? (
                                                          <br />
                                                      ) : (
                                                          ""
                                                      )}
                                                  </>
                                              ) : (
                                                  ""
                                              )}
                                              <Text>
                                                  {task.payout
                                                      ? payout.stage ===
                                                        "requested"
                                                          ? "being paid"
                                                          : "paid"
                                                      : "good for payout"}
                                              </Text>
                                          </View>
                                          <View
                                              padding="medium"
                                              style={{ flexGrow: 1 }}
                                          >
                                              {j < 1 || isMobile ? (
                                                  <>
                                                      <u>Date:</u>
                                                      <br />
                                                      {j < 1 && !isMobile ? (
                                                          <br />
                                                      ) : (
                                                          ""
                                                      )}
                                                  </>
                                              ) : (
                                                  ""
                                              )}
                                              <Text>
                                                  {task.RequestTime.split(
                                                      "T"
                                                  ).shift()}
                                              </Text>
                                          </View>
                                      </Flex>
                                  )}
                              </Collection>
                          </React.Fragment>
                      ) : (
                          ""
                      )
                  )
                : ""}

            {/* Payout destination setup modal */}
            <Modal isOpen={modalShown} onClose={() => setShowModal(false)}>
                <Heading level={3} fontWeight={"bold"} textAlign="center">
                    Last step before getting paid
                    <br />${readyPayouts}
                </Heading>
                <br />
                <br />
                <SelectField
                    label="Select One of Available Payout Destinations:"
                    value={payoutDestinationType}
                    onChange={(e) => {
                        setPayoutDestinationType(e.target.value);
                        setPayoutDestinationTypeError(false);
                        setPayoutDestinationError(false);
                        setPayoutDestination("");
                    }}
                    hasError={payoutDestinationTypeError}
                >
                    <option value="paypal">PayPal</option>
                    <option value="stripe">Stripe</option>
                    <option value="zelle">Zelle</option>
                    <option value="bizum">Bizum</option>
                </SelectField>
                <br />
                <TextField
                    label="Email/Phone Identifer for the Destination:"
                    value={payoutDestination}
                    onChange={(e) => {
                        setPayoutDestination(e.target.value);
                        setPayoutDestinationError(false);
                    }}
                    hasError={payoutDestinationError}
                />
                <br />
                <Button
                    onClick={() => {
                        if (
                            payoutDestinationType.length > 0 &&
                            payoutDestination.length > 0
                        ) {
                            setModalButtonLoading(true);
                            handlePayoutUpdate();
                        } else {
                            if (payoutDestinationType.length < 1) {
                                setPayoutDestinationTypeError(true);
                            }
                            if (payoutDestination.length < 1) {
                                setPayoutDestinationError(true);
                            }
                        }
                    }}
                    size="large"
                    variation="primary"
                    isLoading={modalButtonLoading}
                    disabled={modalButtonLoading}
                >
                    SEND PAYOUT TO THIS DESTINATION
                </Button>
            </Modal>
        </>
    );
}
